import React from "react";
import { graphql } from "gatsby";

import ModuleLoader from "../../modules";
import Layout from "../../components/layout";

import {
  getLocale,
  setLocale,
  getSkin,
  setSkin,
} from "../../utils/localStorage";

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      pageData: this.props.data.strapiPages,
      renderedModules: [],
    };
  }

  componentDidMount() {
    const { modules, skin } = this.state.pageData;

    let urlLocale =
      window.location.pathname.split("/") &&
      window.location.pathname.split("/")[1];
    let languageOnStorage = getLocale();
    if (urlLocale && (!languageOnStorage || languageOnStorage !== urlLocale)) {
      setLocale(urlLocale);
    }

    if (skin && (!getSkin() || getSkin() !== skin)) {
      setSkin(skin);
    }

    this.prepareModules(modules);
  }

  prepareModules = async (propsModules) => {
    const modules = propsModules
      .sort((a, b) => {
        let aPos = 99999;
        let bPos = 99999;

        if (a && a.blockSettings && a.blockSettings.position) {
          aPos = a.blockSettings.position;
        }

        if (b && b.blockSettings && b.blockSettings.position) {
          bPos = b.blockSettings.position;
        }

        return aPos - bPos;
      })
      .map((module, index) => {
        const moduleName = module.strapi_component.replace("elements.", "");
        return (
          <ModuleLoader
            key={`module_${index}`}
            config={module}
            name={moduleName}
          />
        );
        /*return import(`../../modules/${moduleName}`)
        .then((importedModule) => {
          const View = importedModule;
          return <View.default config={module} />;
        })
        .catch((error) => {
          return (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <h1>MISSING CONFIGURATION FOR MODULE {moduleName}</h1>
            </div>
          );
        });*/
      });

    Promise.all(modules).then((res) => {
      this.setState({ ...this.state, renderedModules: res, loading: false });
    });
  };

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <Layout metatags={this.state.pageData.metatags}>
        {this.state.renderedModules}
      </Layout>
    );
  }
}

export const query = graphql`
  query ($id: String!) {
    strapiPages(id: { eq: $id }) {
      id
      name
      slug
      skin
      metatags {
        title
        description
        keywords
        robots
      }
      modules
    }
  }
`;

export default Page;
